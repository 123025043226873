import DrawerAppBarHOC from "../home/DrawerAppBar";
import { Box, CircularProgress, FormControlLabel, Switch } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import OrderHistoryItem from "./OrderHistoryItem";
import withAuthentication from "../base/AuthComponent";
import { useEffect, useState } from "react";
import { Dish } from "../menu/menuTypes";
import { getOrderList, setOnlyToBePrinted } from "./ordersSlice";
import { ORDER_LIST } from "./orderHistoryActions";
import { HttpRequest } from "../base/HttpModels";
import { BaseRequest } from "../base/BaseRequest";
import { USER_LIST } from "../users/usersActions";
import { getUserList } from "../users/usersSlice";
import { logMessage } from "../utils/logger";
import { isMobile } from "react-device-detect";

function OrderHistoryComponentBody() {
  const orders = useAppSelector((state) => state.ordersState.orders);
  const menu = useAppSelector((state) => state.menuState.menu);
  const token = useAppSelector((state) => state.loginState.token);
  const users = useAppSelector((state) => state.userState.users);
  const onlyToBePrinted = useAppSelector((state) => state.ordersState.onlyToBePrinted);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (token !== undefined && token !== null && token !== "") {
      const orderListRequest: HttpRequest<BaseRequest> = {
        action: ORDER_LIST,
        parameters: {
          user_token: token,
        },
      };
      dispatch(getOrderList(orderListRequest));
    }
  }, [dispatch, token]);

   useEffect(() => {
     const interval = setInterval(() => {
       if (token !== undefined && token !== null && token !== "") {
         const orderListRequest: HttpRequest<BaseRequest> = {
           action: ORDER_LIST,
           parameters: {
             user_token: token,
           },
         };
         dispatch(getOrderList(orderListRequest));
       }
     }, 30000);
     return () => {
       clearInterval(interval);
     };
   }, []);


  function findUserNameById(id: number | null) {
    if (id == null) {
      return undefined;
    }
    const user = users.find((user) => user.id === id);
    if (user) {
      return user.name;
    } else {
      return "Utente non trovato";
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        paddingLeft: isMobile ? "0%" : "25%",
        paddingRight: isMobile ? "0%" : "25%",
        display: "block",
        alignItems: "center",
      }}
    >
      <FormControlLabel
        control={
          <Switch
            checked={onlyToBePrinted}
            onChange={() => dispatch(setOnlyToBePrinted(!onlyToBePrinted))}
            color="primary"
          />
        }
        label="Mostra solo da stampare"
      />
      {orders
      .filter((item) => !onlyToBePrinted || item.assigned_to === null)
      .map((item) => (
        <OrderHistoryItem
          key={item.id}
          id={item.id}
          date={new Date(item.creation_date)}
          table={item.table_name}
          price={item.order_lines
            .map((line) => {
              const menuEntry = menu.flatMap((category) => category.dishes).find(
                (entry) => entry.id === line.dish_id
              );
              if (menuEntry) {
                return line.quantity * menuEntry.price;
              } else {
                return 0;
              }
            })
            .reduce((a, b) => a + b, 0)}
          takenBy={findUserNameById(item.opened_by)}
          paidBy={findUserNameById(item.paid_by)}
          servedBy={findUserNameById(item.assigned_to)}
          closedBy={findUserNameById(item.completed_by)}
        />
      ))}
    </Box>
  );
}

function OrderHistoryComponent() {
  return (
    <DrawerAppBarHOC
      title="Lista ordini"
      content={<OrderHistoryComponentBody />}
    />
  );
}

export default withAuthentication(OrderHistoryComponent);
