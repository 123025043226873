import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { roleFromInt } from "../utils/roles";
import { Logout } from "../login/loginSlice";

interface Props {
  title: string;
  content: React.ReactNode;
}

const drawerWidth = 240;
const navItems = [
  ["Ordine al tavolo", "/new_order_table", "3"],
  ["Ordine asporto", "/new_order_takeaway", "3"],
  ["Lista ordini", "/list_orders", "3"],
  ["Lista utenti", "/list_users", "2"],
  ["Gestione menu", "/manage_menu", "2"],
  ["Debug", "/debug", "1"],
  ["Stats", "/stats", "2"],
];

const DrawerAppBarHOC: React.FC<Props> = ({ title, content }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const userName = useAppSelector((state) => state.loginState.name);
  const userRole = useAppSelector((state) => state.loginState.role);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const { pathname } = useLocation();
  const base = `/${pathname.slice(1).split("/").shift()}`;

  console.log(base);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MY SAGRA
      </Typography>
      <Divider />
      <List>
        {navItems
          .filter((item) => Number(item[2]) >= userRole)
          .map((item, index) => (
            <ListItem key={item[0]} disablePadding>
              <ListItemButton
                onClick={(event) => {
                  navigate(item[1]);
                }}
                sx={{
                  textAlign: "center",
                  backgroundColor:
                    item[1] === base || (base === "/" && index === 0)
                      ? "rgba(0, 0, 250, 0.08)"
                      : "inherit",
                }}
              >
                <ListItemText primary={item[0]} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
      <Divider />
      <Typography variant="h6" sx={{ my: 2 }}>
        {userName}
      </Typography>
      <Typography variant="body1" sx={{ my: 1 }}>
        {roleFromInt(userRole)}
      </Typography>
      <Divider />
      <Box sx={{ m: 2 }} />
      <Button
        variant="outlined"
        onClick={(event) => {
          dispatch(Logout());
          localStorage.removeItem("persist:root-2");
        }}
      >
        Logout
      </Button>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems
              .filter((item) => Number(item[2]) >= userRole)
              .map((item, index) => (
                <Button
                  key={item[0]}
                  sx={{
                    color: "#fff",
                    borderBottom:
                      item[1] === base || (base === "/" && index === 0)
                        ? "5px solid white"
                        : "none",
                  }}
                  onClick={(event) => navigate(item[1])}
                >
                  {item[0]}
                </Button>
              ))}
            <Button
              sx={{
                color: "#fff",
              }}
              onClick={(event) => {
                dispatch(Logout());
                localStorage.removeItem("persist:root-2");
              }}
            >
              Logout
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 1, width: "100%" }}>
        <Toolbar />
        {content}
      </Box>
    </Box>
  );
};

export default DrawerAppBarHOC;
